import PropTypes from 'prop-types'
import React from 'react'
import {v4 as uuidv4} from 'uuid'

import {
    AspectRatio,
    Box,
    Flex,
    Heading,
    Image,
    Text,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'

import {styleConfigChakraKeys} from '../../constants'
import {useBooxi} from '../../hooks/use-booxi'
import Link from '../link'

const ServiceCard = ({serviceCard, variant}) => {
    const {buildUrl} = useMultiSite()
    const styles = useMultiStyleConfig(styleConfigChakraKeys.homeServiceCard, {
        variant
    })

    const {instantiateBooxi} = useBooxi()

    const handleClick = () => {
        if (serviceCard.cta.url || serviceCard.cta.externalUrl) {
            const updatedHref = serviceCard.cta.isExternal
                ? serviceCard.cta.externalUrl
                : buildUrl(serviceCard.cta.url)
            window.location.href = updatedHref
        } else {
            instantiateBooxi(serviceCard.serviceId)
        }
    }
    return (
        <Flex
            {...styles.box}
            onClick={handleClick}
            height={{base: '594px', md: '594px', lg: '522px'}}
        >
            {serviceCard?.image?.desktop?.url && (
                <Box {...styles.imageContainer} backgroundColor="red">
                    <Image
                        {...styles.img}
                        src={serviceCard?.image?.desktop?.url}
                        objectFit="cover"
                        height={{base: '424px', lg: '100%'}}
                        width="100%"
                    />
                </Box>
            )}
            <Flex
                {...styles.contentBox}
                paddingX={{base: '32px', lg: '0px'}}
                margin={{base: '0px'}}
                textAlign={{base: 'center', lg: 'start'}}
            >
                {serviceCard?.heading && (
                    <Heading
                        {...styles.text}
                        key={uuidv4()}
                        textAlign={{base: 'center', md: 'start'}}
                        dangerouslySetInnerHTML={{
                            __html: serviceCard.heading
                        }}
                    ></Heading>
                )}
                <Text
                    {...styles.text}
                    key={uuidv4()}
                    maxWidth="60%"
                    textAlign={{base: 'center', md: 'start'}}
                    dangerouslySetInnerHTML={{
                        __html: serviceCard.description
                    }}
                ></Text>
                {serviceCard?.cta && (
                    <Link
                        buttonStyles={{
                            fontSize: '16px',
                            fontWeight: '300',
                            fontFamily: 'Roboto'
                        }}
                        {...styles.link}
                        type={serviceCard.cta.type}
                        text={serviceCard.cta.text}
                    />
                )}
            </Flex>
        </Flex>
    )
}

ServiceCard.propTypes = {
    serviceCard: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        headingType: PropTypes.string.isRequired,
        image: PropTypes.shape({
            desktop: PropTypes.shape({
                url: PropTypes.string.isRequired
            })
        }),
        price: PropTypes.number.isRequired,
        time: PropTypes.number.isRequired,
        cta: PropTypes.shape({
            url: PropTypes.string,
            externalUrl: PropTypes.string,
            text: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            isExternal: PropTypes.bool
        }),
        serviceId: PropTypes.string
    }),
    variant: PropTypes.oneOf(['event', 'service'])
}

ServiceCard.displayName = 'ServiceCard'

export default ServiceCard

import PropTypes from 'prop-types'
import React from 'react'

import {
    AspectRatio,
    Box,
    Flex,
    Heading,
    Image,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {colors, styleConfigChakraKeys} from '../../constants'
import Link from '../link'

const CategoryCard = ({data, index, total}) => {
    const variant = 'service'
    const styles = useMultiStyleConfig(styleConfigChakraKeys.homeServiceCard, {
        variant
    })
    const cta = data?.cta
    const isLastCard = index === total - 1
    const isFirstCard = index === 0
    const height = {base: 'auto', lg: '180px', xl: '230px', '2xl': '320px'}
    const imageHeight = {...height, base: 'auto'}
    return (
        <Flex
            position="relative"
            borderBottomLeftRadius={{
                base: isFirstCard ? '0' : '8px',
                lg: isFirstCard ? '0' : '12px'
            }}
            borderTopLeftRadius={{
                base: isFirstCard ? '0' : '8px',
                lg: isFirstCard ? '0' : '12px'
            }}
            borderBottomRightRadius={{
                base: isLastCard ? '0' : '8px',
                lg: isLastCard ? '0' : '12px'
            }}
            borderTopRightRadius={{
                base: isLastCard ? '0' : '8px',
                lg: isLastCard ? '0' : '12px'
            }}
            overflow="hidden"
            height={height}
            flexDirection={{base: 'column-reverse', lg: 'row'}}
        >
            <Flex
                height="100%"
                gap="20px"
                width={{base: '100%', lg: '40%', xl: '40%'}}
                bg="#F4F3F1"
                alignItems="flex-end"
                paddingTop={{base: '16px', md: '24px', lg: '10px'}}
                paddingX={{base: '16px', md: '24px', lg: '14px', xl: '21px'}}
                paddingBottom={{base: '24px', md: '32px', lg: '13%'}}
            >
                <Flex
                    flexDirection="column"
                    gap={{base: '8px', lg: '6px', xl: '8px'}}
                >
                    <Heading
                        fontSize={{base: '16px', lg: '14px', xl: '16px'}}
                        fontFamily="Raleway"
                        fontWeight="300"
                    >
                        {data?.subTitle}
                    </Heading>
                    <Box
                        fontSize={{
                            base: '32px',
                            md: '32px',
                            lg: '24px',
                            xl: '30px'
                        }}
                        maxWidth="350px"
                        fontFamily="Raleway"
                        fontWeight="300"
                        color={data.theme.color}
                        lineHeight={{base: '37px', md: '28px'}}
                        dangerouslySetInnerHTML={{
                            __html: data?.name
                        }}
                    />

                    <Flex width="fit-content">
                        <Link
                            {...cta}
                            buttonStyles={{
                                backgroundColor: colors.transparent,
                                fontFamily: 'Roboto',
                                fontWeight: '300',
                                fontSize: {
                                    base: '16px',
                                    lg: '16px',
                                    xl: '20px'
                                },
                                textDecoration: 'underline',
                                color: data.theme.color
                            }}
                            type="tertiary"
                        />
                    </Flex>
                </Flex>
            </Flex>
            <AspectRatio
                ratio={358 / 316}
                height={imageHeight}
                bg={
                    data.theme?.backgroundColor
                        ? data.theme.backgroundColor
                        : 'gray'
                }
                width={{base: '100%', lg: '67%'}}
            >
                {/* Needs a child component to be rendered */}
                {data.banner ? (
                    <Image
                        {...styles.img}
                        src={data.banner.desktop.url}
                        objectFit="cover"
                        objectPosition="center"
                        height={imageHeight}
                        style={{cursor: 'pointer'}}
                        onClick={() => (location.href = cta?.url ?? '#')}
                    />
                ) : (
                    <Box></Box>
                )}
            </AspectRatio>
        </Flex>
    )
}

CategoryCard.displayName = 'CategoryCard'

CategoryCard.propTypes = {
    isBanner: PropTypes.bool,
    index: PropTypes.number,
    total: PropTypes.number,
    data: PropTypes.shape({
        name: PropTypes.string,
        subTitle: PropTypes.string,
        cta: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
            externalUrl: PropTypes.string,
            openLinkInNewTab: PropTypes.bool,
            theme: PropTypes.string,
            type: PropTypes.string,
            isExternal: PropTypes.bool
        }),
        title: PropTypes.string,
        banner: PropTypes.shape({
            desktop: PropTypes.shape({
                url: PropTypes.string
            }),
            position: PropTypes.string
        }),
        theme: PropTypes.shape({
            backgroundColor: PropTypes.string,
            color: PropTypes.string
        })
    })
}

export default CategoryCard
